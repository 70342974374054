<title>Log in</title>
<ng-container *ngIf="(loginPage$ | async) as page">

    <div class="wrapper"  id="mainLogInDiv">
        <app-navbar></app-navbar>
        <main class="min-vh-100">
            <div class="tab ">
                <div class="container box" id="containerLandmark">
                    <ul class="nav nav-tabs" id="myTab" role="tablist"  (click) ="getDescribedbyclick($event)"   >
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="signin-tab" data-bs-toggle="tab" 
                                data-bs-target="#signin" type="button" role="tab" aria-controls="signin" 
                               aria-selected="true" aria-describedby="signin" >Sign In</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instruction-tab" data-bs-toggle="tab"
                                data-bs-target="#instruction" type="button" role="tab" aria-controls="instruction"
                                aria-selected="false" >Instructions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq"
                                type="button" role="tab" aria-controls="faq" aria-selected="false" aria-label="Frequently asked questions"
                                 >FAQs</button>
                        </li>
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content" tabindex=""  >
                        <div class="tab-pane active card" id="signin" tabindex="-1" role="tabpanel"  >
                            <img id="divSignInLogo" tabindex="0" *ngIf="!whiteLogo" class="" [src]="'assets/logo/' + page.CarrierLogoFile"  width="100px" />
                            <img id="divSignInLogo" tabindex="0" *ngIf="whiteLogo" class=""  [src]="'assets/logo/' + page.CarrierLogoFile" alt="USAA Logo" width="100px" style="background-color: #0f3555;" />
                            <div class="card-body">
                                <form focusInvalidInput class="form-signin" (ngSubmit)="onSubmit($event, form)" [formGroup]="form"
                                    autocomplete="off">

                                    <div class="form-group form-floating mb-3 position-relative">
                                        <input type="text" class="form-control" id="policyNumber"
                                            formControlName="policyNumber"  required >
                                        <label for="policyNumber" class="sr-only">Please Enter {{poliyNumberText}}</label>
                                        <ng-container
                                            *ngIf="policyNumber.invalid && (policyNumber.dirty || policyNumber.touched )"
                                            class="alert">
                                            <div role="alert">
                                            <small class="error-block text-danger"
                                                *ngIf="policyNumber.errors.required"><i
                                                    class="material-icons me-2">warning</i>{{poliyNumberWarningText}}
                                            </small>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="form-group form-floating mb-3 position-relative">
                                        <input class="form-control" type="password" id="password"
                                            formControlName="password"  required>
                                        <label for="password" class="sr-only"> Please Enter {{policyPasswordText}} </label>
                                        <ng-container *ngIf="password.invalid && (password.dirty || password.touched )"
                                            class="alert">
                                            <div role="alert">
                                            <small class="error-block text-danger" *ngIf="password.errors.required"><i
                                                    class="material-icons me-2">warning</i>Website PIN is required
                                            </small>
                                            </div>
                                        </ng-container>
                                    </div>


                                    <div class="form-row mt-3">
                                        <button class="btn btn-primary me-2" type="submit" name="continueSubmit" 
                                            id="continueSubmit">Sign In</button>
                                        <div role="alert" class="errorMessage" *ngIf="form.submitted && form.invalid"
                                            class="text-danger"> Could not find policy with Number and Website PIN
                                            provided.
                                        </div>
                                    </div>

                                    <div role="alert" class="">
                                        <div class="errorMessage" *ngIf="errorMessage" class="text-danger">
                                            {{errorMessage}}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer align-items-center">
                                <img class="center" src="assets/logo/verisk-logo.svg" width="110" alt="verisk-logo"/>
                            </div>
                        </div>
                        <div class="tab-pane card" id="instruction" role="tabpanel"
                        tabindex="-1">
                            <div class="card-body">
                                <h1 class="" style="color:#006ba6;">Welcome</h1>
                                <div class="card-text" [innerHtml]='page.Language'>
                                </div>
                            </div>  
                        </div>
                        <div class="tab-pane card" id="faq" role="tabpanel" 
                        tabindex="-1">
                            <div class="card-body">

                                <div class="card-text">
                                    <app-faq [id]="carrierId" [type1]="'WebResponseLoginBlock'"></app-faq>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a target="_blank" style="position: relative; color: #07024d; text-decoration: none;"
                        href="https://www.verisk.com/privacy-trademarks-use/iso-online-privacy-notice/" aria-selected="true" aria-describedby="openWarning"><u>We respect your
                        privacy</u></a>
                    <div id="openWarning">
                        <span class="visually-hidden">This link will open a page in new tab.</span>
                    </div>
                </div>
            </div>
        </main>
        <app-footer></app-footer>

    </div>

</ng-container>