import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit,HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService, PageContent } from '@shared/security/auth.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event, Params } from "@angular/router";
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { StorageKey, StorageService } from '@shared/services/storage.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  readonly MAX_RETRY: number = 5;

  form: FormGroup;
  isLoginFailed: boolean = true;
  errorMessage: string;
  loginAttempt: number = 0;
  poliyNumberText: string = "Policy Number";
  poliyNumberWarningText: string = "Policy Number is required";
  whiteLogo: boolean = false;
  
  loginPage$: Observable<PageContent>;
  policyPasswordText: string = "Website PIN";
  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private auth: AuthService, private storage: StorageService) {
    this.form = new FormGroup({
      policyNumber: new FormControl(),
      password: new FormControl
    });
  }
  //TODO :ISOQPCMP-15021 -USAA Accessibility UI 2 - 10 Keyboard user should be able to arrow across the tabs.
  @HostListener('window:keydown', ['$event'])
  moveCell(event: any) {    
    const element = this.getNextInput(
      event.srcElement,
      event.code
    );
    if (!element) {
      return;
    }
    
    this.getDescribedbyfocus(event.srcElement.parentElement.parentElement);

    element.focus();
  }

  getNextInput(currentInput: Element, code: any): HTMLElement {
    if (!currentInput) {
      return null;
    }
    const parentListItem = currentInput.parentElement;
    if(code == "ArrowRight") {

       return parentListItem.nextElementSibling?.firstChild as HTMLElement;
    }
    if(code == "ArrowLeft") {
        
        return parentListItem.previousElementSibling?.firstChild as HTMLElement;
    }
   
  }
  //-------------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        //TODO: hack link in ClosingSummary until data in db can be fixed. 
        if (this.router.url.indexOf('LogOut') > 0) this.auth.logout();
        let carrierId;
        if (params['carrierId']) {
          this.storage.setItem(StorageKey.carrierId, params['carrierId'])
          carrierId = params['carrierId'];
        } else {
          carrierId = this.storage.getItem(StorageKey.carrierId);
        }


        this.loginPage$ = this.auth.getLoginPage(carrierId);
        this.loginPage$.subscribe(res => {
          if (res?.CarrierId >= 0) {
            this.storage.setItem(StorageKey.carrierId, res.CarrierId?.toString());
            this.storage.setItem(StorageKey.bannerText, res.BannerText?.toString());
            this.storage.setItem(StorageKey.callCenterRepTitle, res.CallCenterRepTitle?.toString());
            this.storage.setItem(StorageKey.callCenterPhone, res.CallCenterPhone?.toString());
            this.storage.setItem(StorageKey.callCenterHours, res.CallCenterHours?.toString());
            this.storage.setItem(StorageKey.carrierSpokenName, res.CarrierSpokenName?.toString());

          }
          else {
            throw ({ status: 400, errorMessage: 'no carrier id' });
          }

          if(res?.CarrierId == 104 || res?.CarrierId == 105 || this.router?.url?.indexOf('myusaa') > 0)
           {  
            this.poliyNumberText = "USAA Number";
            this.poliyNumberWarningText = "USAA Number is required"
            this.whiteLogo = true;

           }
        });

        

      },

      error => {
        console.log(error.errorMessage);
      }
    );
  }

  onSubmit($event, form) {
    const carrierId = this.storage.getItem(StorageKey.carrierId)
    this.errorMessage = undefined;
    if (form.valid) {
      this.auth.login(form.controls["policyNumber"].value, form.controls["password"].value, carrierId).subscribe(
        res => {
          if (res.Errors?.length > 0) {
            this.errorMessage = res.Errors[0];
          } else {
            this.router.navigate([`/question/${res.StartPageInfo.SectionTreeGuid}/${res.StartPageInfo.PageNodeTypeCode}`]);
          }
        },
        err => {
          this.errorMessage = "Could not find policy with Number and Website PIN provided.";
        },
        () => {
        }
      )
      return;
    } else {
      this.policyNumber.markAsTouched({ onlySelf: true });
      this.password.markAsTouched({ onlySelf: true });
    }

  }

  get policyNumber() { return this.form.get('policyNumber'); }

  get password() { return this.form.get('password'); }

  getDescribedbyclick(element) { 
    let childs = element.currentTarget as HTMLElement;
     for(var i = 0 ; i < childs.childElementCount;i++)
    {
      if(childs.children[i].querySelector('button').getAttribute('aria-selected') == 'true')
      {
        childs.children[i].querySelector('button').setAttribute("aria-describedby", childs.children[i].querySelector('button').id.replace('-tab',''));        
      }
      else
      {
        childs.children[i].querySelector('button').removeAttribute('aria-describedby');
      }
    }
  }

   getDescribedbyfocus(element) {
    let childs = element as HTMLElement;
    for(var i = 0 ; i < childs.childElementCount;i++)
    {
      if(childs.children[i].querySelector('button').getAttribute('aria-selected') == 'true')
      {
        childs.children[i].querySelector('button').setAttribute("aria-describedby", childs.children[i].querySelector('button').id.replace('-tab',''));        
      }
      else
      {
        childs.children[i].querySelector('button').removeAttribute('aria-describedby');
      }
    }
  }

}